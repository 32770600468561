<template>
    <div>
        <card-header title="Trip" icon="fa-tennis-ball"/>
        <card-toolbar>
            <button @click="$openCard('edit-trip', {tripId: trip.id}, card)"><i class="fas fa-pencil mr-2"/>Edit Trip</button>
            <template #right>
                <button class="icon-only" @click="$openCard('duplicate-trip', {tripId: trip.id}, card)"><i class="far fa-copy"/></button>
            </template>
        </card-toolbar>

        <card-body>
            <img alt="trip" v-if="trip.photo && trip.photo.file" :src="apiUrl + 'api/trip_photos/img/' + trip.photo.file + '/trip'">

            <card-list>
                <data-row title="Title">{{trip.title}}</data-row>
                <data-row title="Location">{{trip.location}}</data-row>
                <data-row title="Dates">{{trip.start_on | moment('MMM Do, YYYY')}} - {{trip.end_on | moment('MMM Do, YYYY')}}</data-row>

                <data-row title="Summary">
                    <strong>{{trip.participant_count}}</strong> Participant<span v-if="trip.participant_count !== 1">s</span>,
                    <strong>{{trip.coach_count}}</strong> Coach<span v-if="trip.coach_count !== 1">es</span>,
                    <strong>{{trip.staff_count}}</strong> Staff,<br>
                    <strong>{{trip.passenger_count}}</strong> Passenger<span v-if="trip.passenger_count !== 1">s</span>,
                    <strong>{{trip.wait_list_count}}</strong> Wait List,
                    <strong>{{trip.cancelled_count}}</strong> Cancelled
                </data-row>

                <separator-list-item/>

                <navigation-list-item
                        icon="fa-suitcase"
                        title="Registrations"
                        :badge="trip.registration_count"
                        :active="child && child.definition.component === 'registrations'"
                        @click="$openCard('registrations', {tripId: trip.id}, card)"
                />

                <navigation-list-item
                        icon="fa-plane"
                        title="Participant Flights"
                        :active="child && child.definition.component === 'trip-flights'"
                        @click="$openCard('trip-flights', {tripId: trip.id}, card)"
                />

                <navigation-list-item
                    icon="fa-comments"
                    title="Communicate"
                />
                <separator-list-item/>

                <task-navigation-list-item
                    title="Trip Itinerary"
                    icon="fa-calendar-alt"
                    :tasks="trip.tasks.events"
                    @click="$openCard('days', {tripId: trip.id}, card)"
                    :active="child && child.definition.component === 'days'"
                />

                <task-navigation-list-item
                    title="Trip Add-Ons"
                    icon="fa-layer-plus"
                    :tasks="trip.tasks.addons"
                    @click="$openCard('trip-addons', {tripId: trip.id}, card)"
                    :active="child && child.definition.component === 'trip-addons'"
                />

                <navigation-list-item
                    icon="fa-images"
                    title="Trip Photos"
                    :active="child && child.definition.component === 'trip-photos'"
                    :badge="trip.photos.length"
                    @click="$openCard('trip-photos', {tripId: trip.id}, card)"
                />
                <navigation-list-item
                    icon="fa-hotel"
                    title="Hotels"
                    :active="child && child.definition.component === 'trip-hotels'"
                    @click="$openCard('trip-hotels', {tripId: trip.id}, card)"
                />
                <navigation-list-item
                    icon="fa-compass"
                    title="Navigator Options"
                    :active="child && child.definition.component === 'trip-app'"
                    @click="$openCard('trip-app', {tripId: trip.id}, card)"
                />
                <navigation-list-item
                    icon="fa-sticky-note"
                    title="Notes"
                    :active="child && child.definition.component === 'trip-notes'"
                    :badge="trip.notes.length"
                    @click="$openCard('trip-notes', {tripId: trip.id}, card)"
                />
                <navigation-list-item
                    icon="fa-clipboard-list-check"
                    title="Reports"
                    :active="child && child.definition.component === 'trip-reports'"
                    @click="$openCard('trip-reports', {tripId: trip.id}, card)"
                />


                <subheader-list-item title="Trip Details" icon="fa-list"/>

                <data-row title="Base Cost">{{trip.cost | currency}}</data-row>
                <data-row title="Terms PDF"><i class="fas mr-2" :class="{'fa-ban has-text-warning': !trip.terms, 'fa-check has-text-primary': trip.terms}"></i>{{trip.terms_filename}}</data-row>
                <data-row title="Banner">{{trip.banner}}</data-row>
                <data-row title="Settings">
                    <span class="mr-4" style="white-space: nowrap"><i class="fas mr-1" :class="{'fa-check': trip.active, 'fa-ban': !trip.active}"></i>Active</span>
                    <span class="mr-4" style="white-space: nowrap"><i class="fas mr-1" :class="{'fa-check': trip.wait_list, 'fa-ban': !trip.wait_list}"></i>Wait List</span>
                    <span class="mr-4" style="white-space: nowrap"><i class="fas mr-1" :class="{'fa-check': trip.featured, 'fa-ban': !trip.featured}"></i>Featured</span>
                    <span class="mr-4" style="white-space: nowrap"><i class="fas mr-1" :class="{'fa-check': trip.closed, 'fa-ban': !trip.closed}"></i>Closed</span>
                    <span class="mr-4" style="white-space: nowrap"><i class="fas mr-1" :class="{'fa-check': trip.questions_enabled, 'fa-ban': !trip.questions_enabled}"></i>Questions Enabled</span>
                </data-row>

                <separator-list-item/>
            </card-list>
        </card-body>
    </div>
</template>

<script>
    import CardHeader from "../../TIER/components/CardHeader";
    import CardList from "../../TIER/components/CardList";
    import CardToolbar from "../../TIER/components/CardToolbar";
    import SearchListItem from "../../TIER/components/SearchListItem";
    import SubheaderListItem from "../../TIER/components/SubheaderListItem";
    import SeparatorListItem from "../../TIER/components/SeparatorListItem";
    import NavigationListItem from "../../TIER/components/NavigationListItem";
    import TripListItem from "../../components/TripListItem";

    import {client as http} from '../../http_client';
    import CardBody from "../../TIER/components/CardBody";
    import DataRow from "../../TIER/components/DataRow";
    import CardListItem from "@/TIER/components/CardListItem";
    import TaskNavigationListItem from "@/components/TaskNavigationListItem";
    import ContainerListItem from "@/TIER/components/ContainerListItem";

    export default {
        name: "Users",
        props: ['card', 'child', 'props'],
        components: {ContainerListItem, TaskNavigationListItem, CardListItem, DataRow, CardBody, TripListItem, NavigationListItem, SeparatorListItem, SubheaderListItem, SearchListItem, CardToolbar, CardList, CardHeader},
        data: function() {
            return {
                trip: {
                    photos: [],
                    notes: [],
                    tasks: {}
                },
                apiUrl: (location.hostname === 'localhost') ? 'http://localhost:3000/' : 'https://portal.tennisventures.net:3000/'
            }
        },
        methods: {
            loadTrip: function(force) {
                this.$emit('loading', true);
                http.get('/api/trips/' + this.props.tripId, {force}).then(response => {
                    this.trip = response.data;
                    this.$emit('title', (this.trip.title.length > 40) ? this.trip.title.substring(0,40)+'...' : this.trip.title);
                    this.$emit('loading', false);
                });
            },
            reload: function() {
                this.loadTrip(true);
            },
            countByType: function(type) {
                let count = 0;
                for (let i = 0; i < this.trip.registrations.length; i++) {
                    if (this.trip.registrations[i].type === type && this.trip.registrations[i].task_complete === 0) count++;
                }
                return count;
            }
        },
        watch: {
            'props.tripId': function() {
                this.loadTrip();
            }
        },
        mounted() {
            this.loadTrip();
        }
    };
</script>

<style lang="scss" scoped>

</style>
